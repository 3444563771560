.menu {
  &__trigger {
    width: 30px;
    height: 80px;
    position: fixed;
    z-index: 100000;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    text-transform: uppercase;
    cursor: pointer;
    padding: 15px;
    box-sizing: content-box;
    @include breakpoint(xs) {
      width: 40px;
      height: 1px;
      background: #989898;
      padding: 0;
      top: 20px;
      right: 15px;
      bottom: auto;
      position: fixed;
      &:before {
        content: "";
        width: 40px;
        height: 1px;
        background: #989898;
        position: absolute;
        top: 10px;
      }
      &:after {
        content: "";
        width: 40px;
        height: 1px;
        background: #989898;
        position: absolute;
        top: 20px;
      }
      .menu__trigger-text {
        display: none;
      }
    }
    &:hover {
      .menu__trigger-text {
        //text-shadow: 0px 0px 1px #989898;
        &:before {
          transform: rotate(90deg) translate3d(0, 15px, 0);
        }
        &:after {
          transform: rotate(90deg) translate3d(0, -15px, 0);
        }
      }
    }
  }
  &__trigger-text {
    transform: rotate(90deg);
    font-size: 12px;
    color: #47505f;
    letter-spacing: 1.74px;
    text-align: center;
    transition: all 0.4s;
    position: relative;
    top: 28px;
    &:before {
      content: "";
      width: 1px;
      height: 30px;
      background: #47505f;
      position: absolute;
      transform: rotate(90deg) translate3d(0, 0, 0);
      transition: all 0.4s;
      top: -10px;
      left: -34px;
    }
    &:after {
      content: "";
      width: 1px;
      height: 30px;
      background: #47505f;
      position: absolute;
      transform: rotate(90deg) translate3d(0, 0, 0);
      transition: all 0.4s;
      top: -10px;
      right: -45px;
    }
  }
  &__box {
    display: flex;
    position: fixed;
    width: 160px;
    height: 100%;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    &.open {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      .menu__item {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
      .lang-selector {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
  &__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 999;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: all 0.3s;
    &.open {
      transform: translate3d(0, 0, 0);
      opacity: 0.3;
    }
  }
  &__wrap {
    width: 100%;
    padding: 45px 20px 60px 40px;
    overflow: auto;
  }
  &__logo {
    > div {
      margin-left: 12px !important;
      margin-bottom: 35px !important;
    }
  }
  &__list {
  }
  &__item {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #596374;
    letter-spacing: 0.6px;
    line-height: 22px;
    padding: 25px 0;
    text-decoration: none;
    display: block;
    text-transform: capitalize;
    position: relative;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    @include breakpoint(xs) {
      padding: 10px 0;
    }
    &:nth-child(1) {
      transition-delay: 0.1s;
    }
    &:nth-child(2) {
      transition-delay: 0.2s;
    }
    &:nth-child(3) {
      transition-delay: 0.3s;
    }
    &:nth-child(4) {
      transition-delay: 0.4s;
    }
    &:nth-child(5) {
      transition-delay: 0.5s;
    }
    &:nth-child(6) {
      transition-delay: 0.6s;
    }
    &:before {
      content: "";
      position: absolute;
      width: 17px;
      height: 1px;
      background: #596374;
      top: 0;
      bottom: 1px;
      left: -27px;
      margin: auto;
      transform: translate3d(-28px, 0, 0);
      opacity: 0;
      transition: 0.3s;
    }
    &.active {
      &:before {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
    &:hover {
      &:before {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
}

.lang-selector {
  display: flex;
  margin-left: 11px;
  margin-bottom: 40px;
  transform: translate3d(100%, 0, 0);
  opacity: 0;
  transition: all 0.5s;
  &__item {
    font-family: "OpenSans", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #47505f;
    letter-spacing: 0.6px;
    text-align: center;
    cursor: pointer;
    position: relative;
    &:first-child {
      padding-right: 15px;
      margin-right: 15px;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        right: 0;
        background: #596374;
      }
    }
    &.active {
      text-shadow: 0px 0px 1px #000000;
    }
  }
}
