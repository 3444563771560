.repertoire-section {
  background: #f5f5f5;
  padding: 60px 0 100px;
  overflow: hidden;
}

.repertoire {
  display: flex;
  width: 100%;
  border-top: 1px solid #596374;
  align-items: center;
  min-height: 60px;
  transform: translate3d(-200px, 0, 0);
  opacity: 0;
  transition: all 0.5s;
  &:nth-child(2n) {
    transform: translate3d(200px, 0, 0);
  }
  &.animate {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    &:nth-child(2n) {
      transform: translate3d(0, 0, 0);
    }
  }
  &:last-child {
    border-bottom: 1px solid #596374;
  }
  &__composer {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #caa88b;
    letter-spacing: 0;
    text-align: center;
    line-height: normal;
    min-width: 30%;
    padding: 10px 15px;
  }
  &__description {
    font-family: "OpenSans", sans-serif;
    font-size: 13px;
    color: #646e7f;
    letter-spacing: 0;
    line-height: 20px;
    min-width: 70%;
    padding: 10px 0;
    > * {
      font-family: "OpenSans", sans-serif;
    }
  }
  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 50px 0;
    transform: translate3d(200px, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    z-index: 1;
    position: relative;
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    @include breakpoint(sm) {
      align-items: center;
      margin-top: 40px;
      flex-direction: column;
    }
    .Dropdown-root {
      min-width: 260px;
      &.is-open {
        .Dropdown-control {
          &:after {
            content: "";
            height: 1px;
            border-bottom: 1px solid #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 10px;
          }
        }
      }
      .Dropdown-control {
        font-family: "OpenSans", sans-serif;
        font-weight: bold;
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0;
        cursor: pointer;
        background-color: #c39e80;
        border: 0;
        border-radius: 0;
        position: relative;
        .Dropdown-arrow-wrapper {
          .Dropdown-arrow {
            width: 17px;
            height: 9px;
            background: url("../../assets/img/down-arrow-white.svg");
            background-size: 17px 9px;
            border: 0;
          }
        }
      }
      .Dropdown-menu {
        border: 0;
        font-family: "OpenSans", sans-serif;
        font-weight: bold;
        font-size: 15px;
        margin-top: 0;
        box-shadow: none;
        background-color: #c39e80;
        .Dropdown-option {
          font-weight: normal;
          color: #fff;
          &:last-child {
            border-radius: 0;
          }
          &.is-selected {
            font-weight: bold;
            background: none;
          }
          &:hover {
            background: #af8e73;
          }
        }
      }
      &:nth-child(1) {
        margin-right: 30px;
        @include breakpoint(sm) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }
      &:nth-child(2) {
        .Dropdown-control {
          background: #596374;
        }
        .Dropdown-menu {
          background-color: #596374;
          .Dropdown-option {
            &:hover {
              background: #505968;
            }
          }
        }
      }
    }
  }
  &__heading {
    font-family: "OpenSans", sans-serif;
    font-size: 15px;
    color: #596374;
    letter-spacing: 1.74px;
    text-align: center;
    line-height: 34px;
    text-transform: uppercase;
    width: 30%;
    margin-bottom: 10px;
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}
