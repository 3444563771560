.footer {
  height: 112px;
  background: #f5f5f5;
  @include breakpoint(sm) {
    height: auto;
    padding: 25px 0;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @include breakpoint(sm) {
      flex-direction: column;
    }
  }
  &__social {
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }
  &__social-link {
    margin-right: 20px;
    img {
      transform: scale3d(1, 1, 1);
      transition: transform 0.25s;
    }
    &:hover {
      img {
        transform: scale3d(1.35, 1.35, 1.35);
      }
    }
    @include breakpoint(sm) {
      margin: 0 20px;
    }
  }
  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__copy-text {
    font-family: "OpenSans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #c39e80;
    letter-spacing: 1.27px;
    text-align: center;
    line-height: 18px;
  }
  &__logo {
    height: 23px;
    width: 163px;
    background: url("../../assets/img/logo-footer.svg");
    background-size: auto 23px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 14px;
  }
  &__author {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    height: 55px;
    width: 98px;
    @include breakpoint(sm) {
      align-items: center;
      margin-top: 20px;
    }
    &:hover {
      .cukrus-logo {
        opacity: 0;
      }
      .cukrus-logo-active {
        opacity: 1;
      }
    }
  }
  &__author-text {
    font-family: "OpenSans", sans-serif;
    font-size: 12px;
    color: #596374;
    letter-spacing: 0.1px;
    text-align: right;
    line-height: 18px;
    margin-bottom: 6px;
    text-transform: uppercase;
    text-align: right;
  }
  .cukrus-logo {
    background: url("../../assets/img/cukrus-logo.svg");
    background-size: 98px 30px;
    height: 30px;
    width: 98px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s;
  }
  .cukrus-logo-active {
    background: url("../../assets/img/cukrus-logo-active.svg");
    background-size: 98px 30px;
    height: 30px;
    width: 98px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0;
    transition: 0.3s;
  }
}
