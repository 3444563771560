.contacts-page {
  background: #596374;
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  // padding-top: 150px;
  @include breakpoint(sm) {
    background: #fff;
    padding: 25px;
    transform: translate3d(500px, 0, 0);
    opacity: 0;
    transition: all 1s;
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  &__background {
    background: url("../../assets/img/contacts-bg.jpg");
    background-size: cover;
    background-position: center;
    padding: 130px 0 215px;
    @include breakpoint(sm) {
      padding: 60px 0 60px;
      background-position: 70% center;
    }
  }
  &.animate {
    .contacts__item {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  &__item {
    font-family: "OpenSans", sans-serif;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    transform: translate3d(500px, 0, 0);
    opacity: 0;
    transition: all 1s;
    transition-delay: 0s;
    color: #596374;
    text-transform: uppercase;
    letter-spacing: 0.62px;
    line-height: 26px;
    @include breakpoint(sm) {
      text-align: center;
    }
    &--name {
      margin-bottom: 23px;
      font-size: 17px;
      letter-spacing: 2.72px;
      line-height: 31px;
      text-transform: uppercase;
      color: #596374;
      font-weight: bold;
    }
  }
}

.button {
  font-family: "OpenSans", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  letter-spacing: 1.74px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: block;
  width: 63px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 1;
  position: absolute;
  bottom: -40px;
  right: 5px;
  &.animate {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
