.big-head {
  height: 100vh;
  position: relative;
  &--dark-mode {
    .big-head__text-one {
      color: #ffffff;
    }
    .big-head__arrow {
      background: url("../../assets/img/down-arrow-white.svg");
      background-size: 24px 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .big-head__line-1,
    .big-head__line-2,
    .big-head__line-3,
    .big-head__line-4 {
      background: rgba(195, 158, 128, 1);
    }
  }
  &--secondary {
    background: url("../../assets/img/events-bg.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint(xs) {
      background-position: 34% center;
    }
    .big-head__text-two {
      left: auto;
      right: 22%;
      top: 20%;
      @include breakpoint(xs) {
        right: 50%;
      }
      &.animate {
        top: 10%;
      }
    }
  }
  &__text {
    height: 250px;
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &__text-one {
    font-family: "Butler", serif;
    font-weight: bold;
    font-size: 140px;
    color: #596374;
    letter-spacing: 0;
    position: absolute;
    top: 45%;
    left: 10%;
    transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
    @include breakpoint(lg) {
      font-size: 90px;
    }
    @include breakpoint(md) {
      font-size: 70px;
    }
    @include breakpoint(sm) {
      font-size: 50px;
    }
    @include breakpoint(xs) {
      font-size: 30px;
    }
    &.animate {
      top: 36%;
      opacity: 1;
    }
  }
  &__text-two {
    font-family: "Butler", serif;
    font-weight: bold;
    font-size: 140px;
    color: rgba(195, 158, 128, 0.54);
    letter-spacing: 0;
    transform: translate3d(90px, 70px, 0px);
    position: absolute;
    top: 49%;
    left: 10%;
    transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
    @include breakpoint(lg) {
      font-size: 90px;
    }
    @include breakpoint(md) {
      font-size: 70px;
    }
    @include breakpoint(sm) {
      font-size: 50px;
    }
    @include breakpoint(xs) {
      font-size: 30px;
      left: -10%;
    }
    &.animate {
      top: 36%;
      opacity: 1;
      @include breakpoint(xs) {
        top: 30%;
      }
    }
  }
  &__arrow {
    background: url("../../assets/img/down-arrow.svg");
    background-size: 24px 12px;
    background-repeat: no-repeat;
    background-position: center;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3%;
    margin: auto;
    cursor: pointer;
    animation: float 4s ease-in-out infinite;
  }
  &__line-1 {
    background: #59637454;
    position: absolute;
    width: 1px;
    height: 0%;
    top: 0;
    left: 25%;
    transition: all 3s cubic-bezier(0.22, 0.61, 0.36, 1);
    &.animate {
      height: 34%;
    }
  }
  &__line-2 {
    background: #59637454;
    position: absolute;
    width: 0%;
    height: 1px;
    top: 62%;
    right: 0;
    transition: all 4s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-delay: 0.3s;
    &.animate {
      width: 40%;
    }
  }
  &__line-3 {
    background: #59637454;
    position: absolute;
    width: 0%;
    height: 1px;
    top: 28%;
    left: 0;
    transition: all 4s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-delay: 0.15s;
    &.animate {
      width: 63%;
    }
  }
  &__line-4 {
    background: #59637454;
    position: absolute;
    width: 1px;
    height: 0%;
    top: 0;
    right: 31%;
    transition: all 3s cubic-bezier(0.22, 0.61, 0.36, 1);
    &.animate {
      height: 49%;
    }
  }
  // &__line-5 {
  //   background: #59637454;
  //   position: absolute;
  //   width: 0%;
  //   height: 1px;
  //   bottom: 25%;
  //   left: 0;
  //   transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
  //   transition-delay: 0.5s;
  //   &.animate {
  //     width: 20%;
  //   }
  // }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-25px);
  }
  100% {
    transform: translatey(0px);
  }
}
