.events-page {
}

.event {
  &__wrap {
    position: relative;
    padding: 30px 90px;
    background: #fff;
    @include breakpoint(sm) {
      padding: 5px 40px;
      background: #fff;
    }
  }
  &__list {
    margin-bottom: 130px;
    margin-top: 50px;
  }
  &__item {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #596374;
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    @include breakpoint(xs) {
      display: flex;
      flex-direction: column;
    }
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &:last-child {
      border-bottom: 1px solid #596374;
    }
  }
  &__left {
    width: 40%;
    @include breakpoint(xs) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &__right {
    width: 60%;
    @include breakpoint(xs) {
      width: 100%;
    }
  }
  &__additional-text {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 11px;
    color: #596374;
    letter-spacing: 0.8;
    line-height: 30px;
  }
  &__title {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #596374;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__description {
    font-family: "OpenSans", sans-serif;
    font-size: 17px;
    color: #596374;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__link {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: none;
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      width: 15px;
      height: 2px;
      background: #c39e80;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  &__date {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__time {
    font-family: "OpenSans", sans-serif;
    font-size: 17px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__address {
    font-family: "OpenSans", sans-serif;
    font-size: 17px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
  }
  &__not-found {
    min-height: 50vh;
    display: flex;
    align-items: center;
  }
}

.more-btn {
  background: url("../../assets/img/down-arrow.svg");
  background-size: 24px 12px;
  background-position: center;
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;
  cursor: pointer;
}
