.section {
  &__title {
    display: flex;
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  &__title-text-one {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #596374;
    letter-spacing: 1.74px;
    line-height: 34px;
    position: relative;
    padding-right: 20px;
    margin-right: 20px;
    text-transform: uppercase;
    &:after {
      content: "";
      height: 15px;
      width: 2px;
      background: #596374;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      margin: auto;
    }
  }
  &__title-text-two {
    font-family: "OpenSans", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #596374;
    line-height: 34px;
  }
}
