.animate.col-md-4 {
  &:nth-child(1) {
    .upcoming__item {
      &:before {
        transition-delay: 0;
      }
      > div {
        transition-delay: 0.4s;
      }
    }
  }
  &:nth-child(2) {
    .upcoming__item {
      &:before {
        transition-delay: 0.4s;
      }
      > div {
        transition-delay: 0.8s;
      }
    }
  }
  &:nth-child(3) {
    .upcoming__item {
      &:before {
        transition-delay: 0.8s;
      }
      > div {
        transition-delay: 1.2s;
      }
    }
  }
  .upcoming__item {
    &:before {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    > div {
      opacity: 1;
    }
  }
}

.upcoming {
  background: #596374;
  padding: 60px 0 100px;
  &__heading {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 1.74px;
    line-height: 34px;
    text-transform: uppercase;
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
    transition: all 0.5s;
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    @include breakpoint(xs) {
      text-align: center;
    }
  }
  &__link {
    text-decoration: none;
  }
  &__item {
    position: relative;
    margin-top: 120px;
    min-height: 200px;
    padding: 0 65px;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    @include breakpoint(lg) {
      padding: 0;
    }
    > div {
      padding: 5px 0;
      opacity: 0;
      transition: all 1s;
    }
    &:before {
      content: "";
      height: 37px;
      width: 1px;
      background: #ffffff;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -65px;
      opacity: 0;
      transform: translate3d(0, -100px, 0);
      transition: all 1.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
  }
  &__title {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 19px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
  }
  &__subtitle {
    font-family: "OpenSans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
  }
  &__address {
    font-family: "OpenSans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #c39e80;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
  }
  &__date {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #c39e80;
    letter-spacing: 0;
    text-align: center;
    line-height: 25px;
  }
}
