@mixin breakpoint($width) {
  @if $width == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $width == lg {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  } @else if $width == md {
    @media only screen and (max-width: 991px) {
      @content;
    }
  } @else if $width == sm {
    @media only screen and (max-width: 767px) {
      @content;
    }
  } @else if $width == xs {
    @media only screen and (max-width: 576px) {
      @content;
    }
  } @else {
    @media only screen and (max-width: $width) {
      @content;
    }
  }
}
