.info-section {
  padding: 35px 0 100px;
  min-height: 100vh;
  @include breakpoint(xs) {
    overflow: hidden;
  }
  &--second-theme {
    .quote {
      &__symbol {
        background: url("../../assets/img/quote-blue.svg");
        background-size: 96px 81px;
      }
      &__text {
        color: #6f88b7;
      }
      &__author {
        color: #6f88b7;
      }
      &__author-subtitle {
        color: #6f88b7;
      }
    }
  }
  &__wrap {
    &.animate {
      .quote__text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      .quote__author {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      .quote__author-subtitle {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      .quote__symbol {
        opacity: 1;
      }
      .info-section__text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &__text {
    font-family: "OpenSans", sans-serif;
    font-size: 13px;
    color: #596374;
    letter-spacing: 0;
    text-align: justify;
    line-height: 20px;
    margin-top: 35px;
    transform: translate3d(0, 200px, 0);
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-delay: 1.2s;
    * {
      font-family: "OpenSans", sans-serif !important;
      font-size: 13px !important;
      color: #596374 !important;
    }
  }
  &__image-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 70px;
    @include breakpoint(sm) {
      margin-top: 50px;
    }
  }
  &__image-container {
    position: absolute !important;
    z-index: 2;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 390px;
    height: 585px;
    transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
    &:hover {
      .info-section__image-container-inner {
        transform: scale(0.95);
        .info-section__image {
          transform: scale(1.2);
        }
      }
    }
    @include breakpoint(md) {
      position: relative !important;
    }
    @include breakpoint(sm) {
      transform: translate3d(0, 0, 0) !important;
      max-width: 100%;
    }
  }
  &__image-container-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 1s;
    overflow: hidden;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 1s;
  }
  &__image-artefact {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 20%;
    width: 100%;
    max-width: 390px;
    height: 585px;
    background: #f5f5f5;
    transition: all 2s cubic-bezier(0.22, 0.61, 0.36, 1);
    @include breakpoint(lg) {
      left: -75px;
      z-index: -1;
    }
  }
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-top: 80px;
  &__symbol {
    width: 96px;
    height: 81px;
    background: url("../../assets/img/quote-orange.svg");
    background-size: 96px 81px;
    position: absolute;
    left: 0;
    top: -45px;
    opacity: 0;
    transition: all 1s;
    transition-delay: 0.2s;
  }
  &__text {
    font-family: "Butler", serif;
    font-weight: bold;
    font-size: 25px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 20px;
    //max-width: 580px;
    position: relative !important;
    z-index: 2;
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transform: translate3d(0, 200px, 0);
    @include breakpoint(lg) {
      font-size: 20px;
    }
  }
  &__author {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #c39e80;
    letter-spacing: 0;
    text-align: right;
    line-height: 17px;
    text-transform: uppercase;
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-delay: 0.4s;
    transform: translate3d(0, 200px, 0);
  }
  &__author-subtitle {
    font-family: "OpenSans", sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #c39e80;
    letter-spacing: 0;
    text-align: right;
    line-height: 17px;
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-delay: 0.8s;
    transform: translate3d(0, 200px, 0);
  }
}
