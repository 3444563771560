.painting-page {
}

.video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 30px;
  > div {
    position: absolute;
  }
  &__wrap {
    position: relative;
    margin-bottom: 140px;
    @include breakpoint(xs) {
      margin-bottom: 40px;
    }
  }
  &__container {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s, filter 0.5s ease;
    margin: 0 15px;
    @include breakpoint(xs) {
      margin: 0;
    }
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

//overide
.video__wrap {
  .slick-slider {
    margin-left: -15px;
    @include breakpoint(xs) {
      margin-left: 0;
    }
  }
  .slick-track {
    padding-top: 10px;
  }
  .slick-slide {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: 0.3s;
    &:hover {
      transform: translate3d(0, -10px, 0);
      opacity: 0.8;
    }
    div {
      &:focus {
        outline: none;
      }
    }
  }
  .react-player__play-icon {
    background: url(../../assets/img/play.svg);
    background-size: 57px;
    border: none !important;
    width: 57px;
    height: 57px;
    margin-left: 0 !important;
  }
}

.video__wrap .slick-dots {
  bottom: -35px;
  // @include breakpoint(sm) {
  //   bottom: 35px;
  // }
  li {
    &.slick-active {
      button {
        &:before {
          background: #596374;
          opacity: 1;
        }
      }
    }
    button {
      &:before {
        content: "";
        width: 12px;
        height: 12px;
        background: #c39e80;
        border-radius: 50%;
        opacity: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.article {
  display: flex;
  margin-bottom: 30px;
  max-height: 260px;
  transform: translate3d(0, 200px, 0);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s, filter 0.5s ease;
  @include breakpoint(xs) {
    display: flex;
    flex-direction: column;
    max-height: none;
  }
  &.animate {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &__wrap {
    position: relative;
    margin-bottom: 140px;
    @include breakpoint(xs) {
      margin-bottom: 40px;
    }
    .more-btn {
      position: relative;
      margin-bottom: 0;
      bottom: 0;
    }
  }
  &__image {
    width: 260px;
    min-width: 260px;
    margin-right: 30px;
    @include breakpoint(xs) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
    &:before {
      content: "";
      padding-bottom: 100%;
      display: block;
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #596374;
    letter-spacing: 0;
    line-height: 25px;
  }
  &__date {
    font-family: "OpenSans", sans-serif;
    font-size: 14px;
    color: #596374;
    letter-spacing: 0;
    line-height: 25px;
  }
  &__description {
    font-family: "OpenSans", sans-serif;
    font-size: 17px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 100px;
  }
  &__more {
    font-family: "OpenSans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    color: #c39e80;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: none;
    position: relative;
    padding-left: 20px;
    &::before {
      content: "";
      width: 15px;
      height: 2px;
      background: #c39e80;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
