.home-page {
}

.hero {
  &__background {
    // background: url("../../assets/img/home-bg.jpg");
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-position: center center;
    @include breakpoint(sm) {
      background-position: 81% center !important;
    }
  }
  &__wrap {
    width: 400px;
    position: absolute;
    left: 0;
    right: 0;
    top: 15%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(xs) {
      width: 100%;
    }
  }
  &__logo {
    width: 360px;
    height: 49px;
    background: url("../../assets/img/logo.svg");
    background-size: 360px 49px;
    margin-bottom: 63px;
    animation: bounceIn 2s;
    background-repeat: no-repeat;
    background-position: center;
    @include breakpoint(xs) {
      width: 255px;
      height: 35px;
      background-size: 255px 35px;
    }
  }
  @keyframes bounceIn {
    0% {
      transform: translate3d(0, -100px, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.nav {
  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include breakpoint(xs) {
      justify-content: space-evenly;
    }
  }
  &__item {
    position: relative;
    animation: fadeUp 1s;
    &:nth-child(2) {
      animation: fadeUp 1.4s;
    }
    &:nth-child(3) {
      animation: fadeUp 1.8s;
    }
    @keyframes fadeUp {
      0% {
        transform: translate3d(0, 100px, 0);
        opacity: 0;
      }
      100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 14px;
      background: #596374;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.4s;
    }
    &:before {
      top: -10px;
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }
    &:after {
      bottom: -10px;
      opacity: 0;
      transform: translate3d(0, 20px, 0);
    }
    &:hover {
      a {
        text-shadow: 0px 0px 1px black;
      }
      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
      &:after {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    a {
      font-size: 12px;
      color: $grey;
      letter-spacing: 1.74px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.3s;
      padding: 20px;
      display: block;
      @include breakpoint(xs) {
        padding: 10px;
      }
    }
  }
}
