.gallery {
  &__heading {
    font-family: "Butler", serif;
    font-weight: bold;
    font-size: 80px;
    color: #596374;
    letter-spacing: 0;
    position: relative;
    text-transform: capitalize;
    @include breakpoint(md) {
      font-size: 50px;
    }
    @include breakpoint(xs) {
      font-size: 20px;
      margin-left: 30px;
    }
  }
  &__back {
    background: url("../../assets/img/left-arrow.svg");
    background-size: 12px 22px;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -50px;
    @include breakpoint(xs) {
      background-size: 8px 16px;
    }
  }
  &__title {
    font-family: "OpenSans", sans-serif;
    font-size: 15px;
    color: #596374;
    letter-spacing: 0;
    border-bottom: 1px solid #596374;
    margin: 40px 0;
    padding: 10px 0;
    opacity: 0;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    transform: translate3d(0, 200px, 0);
    position: relative;
    overflow: hidden;
    &.clickable {
      cursor: pointer;
      &:hover {
        .gallery__title-text {
          transform: translate3d(20px, 0, 0);
          text-shadow: 0px 0px 1px #000000;
        }
      }
    }
    &.animate {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  &__title-text {
    position: relative;
    transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    display: block;
    &:after {
      content: "";
      height: 1px;
      width: 15px;
      position: absolute;
      bottom: 0;
      top: 0;
      left: -20px;
      margin: auto;
      background: #596374;
    }
  }
  &__list {
    margin-top: 100px;
    @include breakpoint(xs) {
      margin-top: 40px;
    }
  }
  &__item-box {
    position: relative;
    opacity: 0;
    transform: translate3d(0, 200px, 0);
    transition: transform 0.5s, opacity 0.5s, filter 0.5s ease-out;
    overflow: hidden;
    &.animate {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &:before {
      content: "";
      padding-bottom: 100%;
      width: 100%;
      display: flex;
    }
    &:hover {
      transform: scale(0.95);
      .gallery__item {
        transform: scale(1.2);
      }
    }
  }
  &__item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    transition: transform 0.5s ease-out;
  }
  &__item-wrap {
    transition: all 0.3s;
    margin-bottom: 30px;
    &:nth-child(4n + 1) {
      .gallery__item-box {
        transition-delay: 0s;
      }
    }
    &:nth-child(4n + 2) {
      .gallery__item-box {
        transition-delay: 0.1s;
      }
    }
    &:nth-child(4n + 3) {
      .gallery__item-box {
        transition-delay: 0.15s;
      }
    }
    &:nth-child(4n + 4) {
      .gallery__item-box {
        transition-delay: 0.2s;
      }
    }
  }
  &__modal {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    z-index: 100;
    padding-top: 100px;
    transform: translate3d(0, 100%, 0);
    // opacity: 0;
    transition: all 0.5s;
    @include breakpoint(xs) {
      padding-top: 20px;
    }
    &.active {
      transform: translate3d(0, 0, 0);
      //opacity: 1;
    }
  }
}

//lightbox custom
.ril__toolbar {
  background: none !important;
}
.ril__navButtons {
  &:focus {
    outline: none !important;
  }
}
