@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,800&display=swap&subset=latin-ext");
@import "../assets/fonts/stylesheet.css";
@import "~normalize.css/normalize.css";
@import "~bootstrap-4-grid/scss/grid.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "./variables.scss";
@import "./responsive.scss";
@import "./sections.scss";

@import "../Containers/Home/home.scss";
@import "../Containers/Piano/piano.scss";
@import "../Containers/Photography/photography.scss";
@import "../Containers/Painting/painting.scss";
@import "../Containers/Contacts/contacts.scss";
@import "../Containers/Events/events.scss";
@import "../Containers/Media/media.scss";

@import "../Components/Menu/menu.scss";
@import "../Components/Footer/footer.scss";
@import "../Components/BigHead/bigHead.scss";
@import "../Components/InfoSection/infoSection.scss";
@import "../Components/Repertoire/repertoire.scss";
@import "../Components/Translate/translate.scss";
@import "../Components/UpcomingEvents/upcomingEvents.scss";
@import "../Components/Portfolio/portfolio.scss";
@import "../Components/GalleryItem/galleryItem.scss";

// * {
//   cursor: url("../assets/img/cursor.png"), auto;
//   cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/heart.png"),
//     auto;
// }
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  width: 100%;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

//page
.page {
  transform: none !important;
}

.container {
  &.overflow-hidden {
    overflow: hidden;
  }
  @include breakpoint(xl) {
    max-width: 1170px;
  }
}

.loader {
  &__wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
    &.fade-out {
      //opacity: 0;
      //transform: scale3d(1.7, 1.7, 1.7);
      transform: translate3d(0, 100%, 0);
    }
  }
}

.form {
  > div {
    transform: translate3d(0, 200px, 0);
    opacity: 0;
    transition: all 0.8s;
    &:nth-child(2) {
      transition-delay: 0.2s;
    }
    &:nth-child(3) {
      transition-delay: 0.4s;
    }
  }
  &.animate {
    > div {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}

.input {
  border-bottom: 1px solid #596374;
  position: relative;
  margin-bottom: 15px;

  @include breakpoint(sm) {
    max-width: 100%;
  }

  &--error {
    border-color: red;
  }

  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    font-family: "OpenSans", sans-serif;
    font-size: 12px;
    color: #596374;
    letter-spacing: 1.74px;
    line-height: 30px;
    text-transform: uppercase;
    padding: 0;
    &:focus {
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #596374 inset !important;
      -webkit-text-fill-color: #fff !important;
    }
    &::-webkit-input-placeholder {
      color: #596374;
    }
    &:-ms-input-placeholder {
      color: #596374;
    }
    &::placeholder {
      color: #596374;
    }
  }
  &__error {
    color: #bb2a2a;
    font-size: 12px;
    margin-top: -13px;
    position: relative;
    top: -2px;
    left: 15px;
  }
}

.text-area {
  margin-top: 35px;
  margin-bottom: 75px;
  position: relative;
  &:after {
    content: "";
    background: #596374;
    position: absolute;
    width: 100%;
    height: 100%;
    right: -30px;
    bottom: -40px;
    z-index: 0;
    @include breakpoint(sm) {
      right: -10px;
    }
  }
  &--error {
    border-color: red;
  }
  textarea {
    resize: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    color: #ffffff;
    line-height: 21px;
    background: #fff;
    width: 100%;
    height: 300px;
    padding: 12px 15px;
    font-family: OpenSans;
    font-size: 12px;
    color: #596374;
    letter-spacing: 0;
    position: relative;
    z-index: 1;
    &:focus {
      outline: none;
    }
  }
  // &__error {
  //   color: #bb2a2a;
  //   font-size: 12px;
  //   margin-top: -20px;
  //   margin-bottom: 10px;
  //   position: relative;
  //   top: -2px;
  // }
}

// .example {
//   font-family: "Open Sans", sans-serif;
//   font-family: "Libre \\\\\\\\\", serif;
// }

//page enter animation
// .fade-enter {
//   opacity: 0.01;
//   .page {
//     .big-head {
//       &__text {
//         opacity: 0.01;
//         transform: translate3d(0, -100px, 0);
//       }
//     }
//   }
// }

// .fade-enter.fade-enter-active {
//   opacity: 1;
//   transition: all 750ms ease-in;
//   .page {
//     .big-head {
//       &__text {
//         opacity: 1;
//         transform: translate3d(0, 0, 0);
//       }
//     }
//   }
// }

// .big-head {
//   &__text {
//     transition: all 750ms ease-in;
//   }
// }

// //page exit animation
// .fade-exit {
//   .page {
//     opacity: 1;
//   }
// }
// .fade-exit.fade-exit-active {
//   .page {
//     opacity: 0.01;
//     transition: opacity 500ms ease-in;
//   }
// }

.route-section {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000000 !important;
}
